import request from '@/request/request'
// 页面的自己的信息
export function myindex(data){
    return request({
        url:'/Property/Home/UserInfo',
        method:'post',
        data
    })
}
// 日历
export function mytask(data){
    return request({
        url:'/Property/Home/UserTaskCalendar ',
        method:'post',
        data
    })
}
// 折线图
export function myline(data){
    return request({
        url:'/Property/Home/UserCount',
        method:'post',
        data
    })
}
export function mylines(data){
    return request({
        url:'/Property/Home/UserCountChart',
        method:'post',
        data
    })
}